import { FC, useState, useEffect } from 'react'
import {
  Product,
  ProductKeys,
  ProductSpec,
  ProductMediaType,
  ProductImage,
} from '@common/types/product'
import { productSpecsMetafields } from '@framework/utils/common'
import s from './ProductView.module.scss'
import { ProductSlider, ProductViewMedia } from '@components/product'
import { useMedia } from '@hooks'
import { Slider, Column, Grid, Breadcrumb } from '@components/common'
import { Image, ExternalLink } from '@components/ui'
import SEO from '@components/common/SEO/SEO'
import ProductViewManufacturer from './ProductViewManufacturer'
import ProductViewAddToCart from './ProductViewAddToCart'
import ProductViewDescription from './ProductViewDescription'
import {
  getProductVariant,
  selectDefaultOptionFromProduct,
  SelectedOptions,
} from '../helpers'

interface Props {
  product: Product
  recProducts: Product[]
}

const placeholderImage = '/static_images/ts-pool-supply-square-pool.jpg'

const ProductView: FC<Props> = ({ product, recProducts }) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({})
  const variant = getProductVariant(product, selectedOptions)
  const isDesktop = useMedia(
    // Media queries
    ['(min-width: 1200px)'],
    // Column counts (relates to above media queries by array index)
    [true],
    // Default column count
    false
  )
  let seoImages = [] as ProductImage[]

  product?.media.forEach((media) => {
    if (media.type === ProductMediaType.Image) {
      seoImages.push(media as ProductImage)
    }
  })

  const unadvertisedPrice = product.specialPrice
  const isUnAdvertised = Boolean(unadvertisedPrice)

  var showPriceMatch = false
  var showBestPrice = false
  let specs = productSpecsMetafields.map((spec) => {
    if (spec.label === "Price Match") {
      if (product[spec.key as ProductKeys] === "Yes") showPriceMatch = true
      return {}
    }
    if (spec.label === "Best Price Available") {
      if (product[spec.key as ProductKeys] === "Yes") showBestPrice = true
      return {}
    }
    else {
      return {
        value: product[spec.key as ProductKeys] ?? '',
        label: spec.label,
      }
    }
  })

  let productsku = '';
  if (variant) {
    specs.unshift({
      value: variant.sku!,
      label: 'SKU',
    })
    productsku = variant.sku
  }

  let breadcrumbs = []

  breadcrumbs.push({
    title: 'SHOP',
    link: '/',
  })

  const categoryCollections = product.collections.filter((c) => c.category)

  if (categoryCollections.length) {
    breadcrumbs.push({
      title: categoryCollections[0].title,
      link: `/collections/${categoryCollections[0].path}`,
    })
  }

  breadcrumbs.push({
    title: product.name,
  })


  console.log(product);
  const productSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": product.name,
    "sku": productsku,
    "description": product.description,
    "image": seoImages[0]?.url,
    "brand": {
      "@type": "Brand",
      "name": "TS Pool Supply"
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": product.price?.value || "0.00",
      "availability": product.availableForSale ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
    }
  }

  console.log(productSchema);


  useEffect(() => {
    selectDefaultOptionFromProduct(product, setSelectedOptions)
  }, [product])

  return (
    <>
      <section className={s.root}>
        <div className={s['name-wrap']}>
          <Breadcrumb crumbs={breadcrumbs} />
          <h1 className={s.name}>{product.name}</h1>
        </div>
        <Grid xsGutter={30}>
          <Column md={4} mdOrder={1}>
            <Slider columns={1}>
              {product.media.length ? (
                product.media.map((media, index) => (
                  <div className={s['image-wrap']} key={index}>
                    <ProductViewMedia media={media} />
                  </div>
                ))
              ) : (
                <div className={s['image-wrap']}>
                  <Image
                    alt={product.name}
                    src={placeholderImage}
                    layout="fill"
                    objectFit="scale-down"
                  />
                </div>
              )}
            </Slider>
            {isDesktop && product.manufacturerUrl && (
              <ProductViewManufacturer link={product.manufacturerUrl} />
            )}
          </Column>
          <Column md={4} xl={3} mdOrder={3}>
            <ProductViewAddToCart
              product={product}
              variant={variant}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              showBestPrice={showBestPrice}
              unadvertisedPrice={isUnAdvertised || false}
              discountOff={product.hasDiscountOff}
              beatCompetitorsPrice={product.hasBeatCompetitorsPrice}
              hasRequestInstall={product.hasRequestInstall}
            />
            {showPriceMatch && (
              <ExternalLink className={s['pricematch']} href="https://shop.tspoolsupply.com/pages/save-with-price-match">
                Exclusive Price
              </ExternalLink>
            )}
          </Column>
          <Column md={4} xl={5} mdOrder={2}>
            <ProductViewDescription
              description={product.descriptionHtml}
              specs={specs as ProductSpec[]}
            />
            {!isDesktop && product.manufacturerUrl && (
              <ProductViewManufacturer link={product.manufacturerUrl} />
            )}
          </Column>
        </Grid>
      </section>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(productSchema) }}
      />
      {recProducts.length > 0 && (
        <ProductSlider
          title="RECOMMENDED PRODUCTS"
          products={recProducts}
        ></ProductSlider>
      )}
      <SEO
        title={product.seo?.title || product.name}
        description={product.seo?.description || product.description}
        openGraph={{
          type: 'website',
          title: product.seo?.title || product.name,
          description: product.seo?.description || product.description,
          images: [
            {
              url:
                seoImages[0]?.url ||
                '/static_images/ts-pool-supply-square-pool.jpg',
              width: seoImages[0]?.width,
              height: seoImages[0]?.height,
              alt: product.name,
            },
          ],
        }}
      />
    </>
  )
}

export default ProductView
